'use client';
import { usePathname } from 'next/navigation';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useIam } from '../../../../../services/IAm/hooks';
import { ButtonLink } from '../../../../../components/ButtonLink';
import EducatorSignUpLink from '../../../../../components/EducatorSignUpLink';
import Typical from '../../../../../components/Typical';
import Button from '../../../../../components/Button';
import styles from './styles.module.scss';

export const PrimaryCtaLink: FunctionComponent<{ className?: string }> = ({ className }) => {
    const { iam } = useIam();
    const pathname = usePathname();

    if (iam.authenticated && iam.educators && iam.educators.length > 0) {
        return (
            <ButtonLink
                className={className}
                preset="primary"
                href={process.env.NEXT_PUBLIC_ADMIN_URL as string}
            >
                Admin Dashboard
            </ButtonLink>
        );
    }

    if (pathname === '/for-educators') {
        return (
            <Button as={EducatorSignUpLink} preset="primary" className={className}>
                Get Started for Free
            </Button>
        );
    }

    if (pathname && ['/', '/pricing', '/advertise', '/about', '/contact'].includes(pathname)) {
        return (
            <Button
                as={EducatorSignUpLink}
                preset="textButton"
                className={classNames(styles.typicalSignupLink, className)}
            >
                Create{' '}
                <Typical
                    steps={[
                        'a Course',
                        2000,
                        'an Event',
                        2000,
                        'a Webinar',
                        2000,
                        'a Workshop',
                        2000,
                        'a Class',
                        2000,
                        'a Membership',
                        2000,
                        ' Training',
                        2000,
                    ]}
                    loop="Infinity"
                    wrapper="span"
                />
            </Button>
        );
    }

    return (
        <ButtonLink className={className} preset="textButton" href="/for-educators">
            Create a Course
        </ButtonLink>
    );
};
